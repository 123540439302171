import { MenuServiceOrderItemPrinter, MenuServiceProductStep } from "./menu-service.model";

export class Order {
  public OrderControlId: string;
  public CompanyId: number;
  public TotalValue: number;
  public SubtotalValue: number;
  public Items: OrderItem[];
  public ItemsQuantity: number;

  constructor(companyId: number, orderControlId: string) {
    this.CompanyId = companyId;
    this.OrderControlId = orderControlId;
    this.TotalValue = 0;
    this.Items = [];
    this.SubtotalValue = 0;
    this.ItemsQuantity = 0;
  }
}

export class OrderItem {
  public ProductId: string;
  public ProductBarcode: string;
  public ProductName: string;
  public OriginalProductName: string;
  public ProductTypeName?: string;
  public AdditionalInfo?: string;
  public ProductUnitAcronym?: string;
  public MarketingClassificationId?: string;
  public MarketingClassificationName?: string;
  public OriginalMarketingClassificationName?: string;
  public Quantity: number;
  public QuantityToShow: number;
  public MaxQuantityOnSale?: number;
  public ProductStepMinValue?: number;
  public SellValue: number;
  public OriginalSellValue: number;
  public GrossValue: number;
  public TotalValue: number;
  public Observation: string;
  public CanBeFractioned?: boolean;
  public GetWeight?: boolean;
  public ProductIsCompositionMember?: boolean;
  public ImageUrl?: string;
  public ComboRequiredValuesComplete?: boolean;
  public IsEdit?: boolean;
  public IsProductsAgeRestricted?: boolean;
  public StepIndex?: number;
  public ApplyServiceCharge: boolean;
  public IsSplitOnSale: boolean;
  public HasComplements?: boolean;
  public SubItems: OrderSubItem[];
  public ProductSteps?: MenuServiceProductStep[];
  public Printers?: MenuServiceOrderItemPrinter[];
  public ServesHowManyPeople?: number;
}

export class OrderSubItem {
  public ProductId: string;
  public ProductBarcode?: string;
  public ProductName: string;
  public OriginalProductName: string;
  public Quantity: number;
  public QuantityToShow?: number;
  public SellValue: number;
  public OriginalSellValue: number;
  public GrossValue: number;
  public TotalValue: number;
  public CanBeFractioned?: boolean;
  public MarketingClassificationId?: string;
  public MarketingClassificationName?: string;
  public OriginalMarketingClassificationName?: string;
  public ProductTypeName?: string;
  public StepIndex?: number;
  public ApplyServiceCharge: boolean;
  public IsSplitOnSale: boolean;
  public Printers?: MenuServiceOrderItemPrinter[];

  constructor() {
    this.Quantity = 0;
    this.TotalValue = 0;
  }
}

export class OrderSummary {
  totalValue: number;
  subtotalValue: number;
  paidValue: number;
  changeValue: number;
}
